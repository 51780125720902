import { FAQS_SECTION } from "../../shared/data/app-faq.data";
import AppFaqItem from "../../shared/modules/AppFaq/AppFaqItem";

export function FaqsContent() {
  return(
    <section className="section-faq2 dibvv">
      <div className="page-padding-2 faq divfaq">
        <div className="container-large">
          <div className="padding-vertical padding-xhuge">
            <div className="margin-bottom margin-xxlarge">
              <div className="max-width-large">
                <div className="margin-bottom margin-small">
                  <div className="text-weight-semibold">INFORMATION CENTER</div>
                  <h2 className="heading-7 faq">FAQs</h2>
                </div>
              </div>
            </div>
            <div className="faq2_component">
              {
                FAQS_SECTION.map((faq) => {
                  return <AppFaqItem key={faq.id} faq={faq}></AppFaqItem>
                })
              }
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}