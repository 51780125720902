import './App.css';
import './shared/css/normalize.css';
import './shared/css/webflow.css';
import './shared/css/bridgefy2022.webflow.css';
import BridgefyRoutes from './routes/Routes';

function App() {
  return (
    <div>
      <link rel="preconnect" href="https://fonts.googleapis.com"/>
      <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin='true'/>
      <link href="https://fonts.googleapis.com/css2?family=Oswald:wght@200;300;400;500;600;700&display=swap" rel="stylesheet"></link>
      <BridgefyRoutes></BridgefyRoutes>
    </div>
  );
}

export default App;
