import SdkFooter from "../../shared/modules/SdkFooter/SdkFooter";
import SdkNavbar from "../../shared/modules/SdkNavbar/SdkNavbar";
import { FaqsContent } from "./FaqsContent";

export default function Faqs () {
  return (
    <div className="body-2">
      <SdkNavbar></SdkNavbar>
      <FaqsContent></FaqsContent>
      <SdkFooter></SdkFooter>
    </div>
  );
}
