import { useState } from "react";
import { Link } from "react-router-dom";
import { SDK_NAVBAR_DATA } from "../../data/sdk-navbar.data";
import bridgefyLogo from '../../images/BridgefyLogo1.svg';

export default function SdkNavbarDark(props) {
  const [active, setActive] = useState(false);

  return (
    <>
      <header className="section-header2">
        <div
          data-collapse="medium"
          data-animation="default"
          data-duration="400"
          data-w-id="f2e0c7c9-adb9-10f3-0403-2f021757e928"
          data-easing="ease"
          data-easing2="ease"
          role="banner"
          className="navbar1_component home w-nav">
          <div className="navbar1_container home">
            <Link
              href="sdk.html"
              aria-current="page"
              className="navbar1_logo-link w-nav-brand w--current">
              <img
                src={bridgefyLogo}
                loading="lazy"
                width="150"
                alt=""/>
            </Link>
            <nav
              role="navigation"
              className="responsive-menu navbar1_menu home w-nav-menu">
              {
                SDK_NAVBAR_DATA.map((menu) => {
                  if (menu.external) {
                  return (
                    <a
                      href={menu.link}
                      className="navbar1_link home w-nav-link"
                      key={menu.id}>
                      {menu.title.toUpperCase()}
                    </a>)
                  } else {
                    return (<Link
                      to={menu.link}
                      className="navbar1_link home w-nav-link"
                      key={menu.id}>
                      {menu.title.toUpperCase()}
                    </Link>)
                  }
                })
              }
              <div className="navbar1_menu-buttons">
                <div className="navbar1_button-wrapper">
                  <a
                    href="https://developer.bridgefy.me"
                    target="_blank"
                    rel="noreferrer"
                    className="button-2 max-width-full red home w-button">
                    Sign Up
                  </a>
                </div>
              </div>
            </nav>
            <div
              className={'navbar1_menu-button w-nav-button ' +
              (active ? ' active-menu' : '')}
              onClick={() => setActive(!active)}>
              <div className="menu-icon1 home">
                <div className="menu-icon1_line-top home"></div>
                <div className="menu-icon1_line-middle home">
                  <div className="menu-icon_line-middle-inner"></div>
                </div>
                <div className="menu-icon1_line-bottom home"></div>
              </div>
            </div>
          </div>
          {
            !active || <div
            className="w-nav-overlay responsive-navbar"
            data-wf-ignore=""
            id="w-nav-overlay-0"
            style={{
              height: '5110.34px',
              display: 'block',
            }}>
            <nav
              role="navigation"
              className="navbar1_menu home w-nav-menu"
              style={{
                transform: 'translateY(0px) translateX(0px)',
                transition: 'transform 400ms ease 0s',
                backgroundImage: 'none',
                backgroundColor: '#1e1d27'
              }}
              data-nav-menu-open="">
              <a
                className="navbar1_link home w-nav-link w--current w--nav-link-open"
                href="/">
                HOME
              </a>
              <a
                className="navbar1_link home w-nav-link w--nav-link-open"
                href="/use-cases">
                USE CASES
              </a>
              <a
                className="navbar1_link home w-nav-link w--nav-link-open"
                href="/faq">
                FAQ
              </a>
              <a
                href="https://bridgefy.me/blog/"
                className="navbar1_link home w-nav-link w--nav-link-open">
                BLOG
              </a>
              <a
                className="navbar1_link home w-nav-link w--nav-link-open"
                href="/app">
                APP
              </a>
              <div className="navbar1_menu-buttons">
                <div className="navbar1_button-wrapper">
                  <a
                    href="https://developer.bridgefy.me"
                    target="_blank"
                    rel="noreferrer"
                    className="button-2 max-width-full red home w-button">
                    Sign Up
                  </a>
                </div>
              </div>
            </nav>
          </div>
          }
        </div>
        {props.children}
      </header>
    </>
  );
}
