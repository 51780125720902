import { useState } from "react";
import { Link } from "react-router-dom";
import { SDK_NAVBAR_DATA } from "../../data/sdk-navbar.data";
import bridgefyLogo from '../../images/BridgefyLogo1.svg';

export default function SdkNavbar() {
  const [active, setActive] = useState(false);

  return (
    <>
      <div className="navbar1_component home donate w-nav">
        <div className="navbar1_container home">
          <Link to="/" className="navbar1_logo-link w-nav-brand">
            <img src={bridgefyLogo} loading="lazy" width="150" alt=""/>
          </Link>
          <nav className="navbar1_menu home w-nav-menu responsive-menu">
            {
              SDK_NAVBAR_DATA.map((menu) => {
                if (menu.external) {
                return (
                  <a
                    href={menu.link}
                    className="navbar1_link home donate w-nav-link"
                    key={menu.id}>
                    {menu.title.toUpperCase()}
                  </a>)
                } else {
                  return (<Link
                    to={menu.link}
                    className="navbar1_link home donate w-nav-link"
                    key={menu.id}>
                    {menu.title.toUpperCase()}
                  </Link>)
                }
              })
            }
            <div className="navbar1_menu-buttons">
              <div className="navbar1_button-wrapper">
                <a
                  href="https://developer.bridgefy.me"
                  target="_blank"
                  rel="noreferrer"className="button-2 max-width-full red home w-button">
                  Sign Up
                </a>
              </div>
            </div>
          </nav>
          <div
            className={'navbar1_menu-button w-nav-button display-md ' +
            (active ? ' active-menu' : '')}
            onClick={() => setActive(!active)}>
            <div className="menu-icon1">
              <div className="menu-icon1_line-top"></div>
              <div className="menu-icon1_line-middle">
                <div className="menu-icon_line-middle-inner"></div>
              </div>
              <div className="menu-icon1_line-bottom"></div>
            </div>
          </div>
        </div>

        { !active || <div
          className="w-nav-overlay"
          data-wf-ignore=""
          id="w-nav-overlay-0"
          style={{
            height: '5110.34px',
            display: 'block',
          }}>
          <nav
            role="navigation"
            className="navbar1_menu home w-nav-menu responsive-navbar"
            style={{
              transform: 'translateY(0px) translateX(0px)',
              transition: 'transform 400ms ease 0s',
              backgroundImage: 'none',
              backgroundColor: '#e6e8e6'
            }}
            data-nav-menu-open="">
            <a
              className="navbar1_link home donate w-nav-link w--current w--nav-link-open"
              href="/">
              HOME
            </a>
            <a
              className="navbar1_link home donate w-nav-link w--nav-link-open"
              href="/use-cases">
              USE CASES
            </a>
            <a
              className="navbar1_link home donate w-nav-link w--nav-link-open"
              href="/faq">
              FAQ
            </a>
            <a
              href="https://bridgefy.me/blog/"
              className="navbar1_link home donate w-nav-link w--nav-link-open">
              BLOG
            </a>
            <a
              className="navbar1_link home donate w-nav-link w--nav-link-open"
              href="/">
              APP
            </a>
            <div className="navbar1_menu-buttons">
              <div className="navbar1_button-wrapper">
                <a
                  href="https://developer.bridgefy.me"
                  target="_blank"
                  rel="noreferrer"
                  className="button-2 max-width-full red home w-button">
                  Sign Up
                </a>
              </div>
            </div>
          </nav>
        </div>}

      </div>
    </>
  );
}
