import { Link } from "react-router-dom";
import "./NotFound.css";
import chatImage from "../../shared/images/Chats.svg"

export default function NotFoundContent () {
  return (
    <section>
      <div className="not-found-content">
        <img src={chatImage} alt="" />
        <p className="text-size-medium-3 sales thankstitle _404">
          Sorry! we couldn't find that page.
        </p>
        <p className="text-size-medium-3 sales thankstitle">
          Try visiting our <Link className="link-33" to="/">home page</Link>.
        </p>
      </div>
    </section>
  );
}