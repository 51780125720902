export default function ContactUsContent() {
  return (
    <section className="section-faq2 dibvv">
      <div className="page-padding-2 faq divfaq">
        <div className="container-large">
          <div className="padding-vertical padding-xhuge">
            <div className="margin-bottom margin-xxlarge">
              <div className="max-width-large">
                <div className="margin-bottom margin-small">
                  <h2 className="heading-7 faq">CONTACT US</h2>
                </div>
              </div>
            </div>
            <div className="text-size-medium-4 hero bigger">
              <p>Bridgefy, Inc.</p>
              <p>9429 Harding Ave. PMB 148, Surfside, Florida, United States</p>
              <p>Contact e-mail: <a className="link-16" href="mailto:contact@bridgefy.me">contact@bridgefy.me</a></p>
              <p>Press inquiries: <a className="link-16" href="mailto:press@bridgefy.me">press@bridgefy.me</a></p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}