import { APP_FAQ_DATA } from "../../data/app-faq.data";
import AppFaqItem from "./AppFaqItem";

export default function AppFaq () {

  return (
    <>
      <div className="container-large-2">
      <div className="div-block-6">
          <div className="padding-vertical padding-xhuge faqs">
            <div className="margin-bottom margin-xxlarge faqq _2">
              <div className="max-width-large">
                <div className="margin-bottom margin-small">
                  <div className="text-weight-semibold-4">INFORMATION CENTER</div>
                  <h2 className="heading-7 pricing _2">FAQ</h2>
                </div>
              </div>
            </div>
            <div className="faq2_component">
              {
                APP_FAQ_DATA.map((faq) => {
                  return <AppFaqItem key={faq.id} faq={faq}></AppFaqItem>
                })
              }
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
