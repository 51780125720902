import SdkFooter from "../../shared/modules/SdkFooter/SdkFooter";
import SdkNavbarDark from "../../shared/modules/SdkNavbarDark/SdkNavbarDark";
import UseCasesContent from "./UseCasesContent";

export default function UseCases () {
  return (
    <>
    <div className="body">
      <div className="div usecases">
        <SdkNavbarDark></SdkNavbarDark>
        <UseCasesContent></UseCasesContent>
        <SdkFooter></SdkFooter>
      </div>
    </div>
    </>
  );
}