import frame4 from '../../shared/images/Frame-4.png';
import devshops1 from '../../shared/images/devshops1.png';
import devshops2 from '../../shared/images/devshops2.png';
import revenue from '../../shared/images/revenue.png';
import frame2 from '../../shared/images/Frame-2.png';
import frame1 from '../../shared/images/Frame-1.png';
import useCases5 from '../../shared/images/useCases5.png';
import useCases6 from '../../shared/images/useCases6.png';
import { Link } from 'react-router-dom';

export default function DevshopsContent() {
  return (
    <>
      <header id="start" className="section-header2-2 devshops">
        <div className="page-padding-6">
          <div className="container-large">
            <div className="padding-vertical padding-xhuge">
              <div className="w-layout-grid header2_component">
                <img
                  className="image-26"
                  src={frame4}
                  alt=""
                  data-w-id="68f0f4eb-ec21-efad-0377-10de0a4e8183"
                  id="w-node-_68f0f4eb-ec21-efad-0377-10de0a4e8183-35da6572"
                  loading="lazy"
                  />
                <div className="header2_content-2">
                  <div className="margin-bottom margin-small">
                    <h1 className="heading devshop">
                      <strong className="bold-text-8">
                        Make Your App Work Without the Internet.
                      </strong>
                    </h1>
                  </div>
                  <div className="margin-bottom margin-medium">
                    <p className="text-size-medium-5 hero bigger">Allow your app to send offline messages, location, game moves, and much more! Integrate the Bridgefy SDK to let your users send information through a Bluetooth signal instead of an Internet connection, and reach your users when other apps can’t.</p>
                  </div>
                  <a
                    href="#form"
                    className="button-3 max-width-full red home devshops w-button">
                      <strong>Sign up to learn how!</strong>
                  </a>
                </div>
              </div>
            </div>
            <img src="https://uploads-ssl.webflow.com/62d5fdd7d8412d582512e77c/62d5fdd7d8412d8bb512e797_bluetooth_background.png" loading="lazy" width="766" data-w-id="68f0f4eb-ec21-efad-0377-10de0a4e818f" alt="" className="image-8 blue"/>
          </div>
        </div>
      </header>
      <section id="program" className="div-block-9 devshop">
        <div className="section-layout210-3 devshop">
          <div className="page-padding-5 devshop">
            <div className="container-large-5">
              <div className="padding-vertical padding-xhuge">
                <div id="program" className="w-layout-grid layout210_component-2">
                  <div className="layout210_image-wrapper-2">
                    <img
                      src={devshops1}
                      loading="lazy"
                      alt=""/>
                    </div>
                  <div
                    id="w-node-_68f0f4eb-ec21-efad-0377-10de0a4e8198-35da6572"
                    className="layout210_content">
                    <div className="margin-bottom margin-xsmall devshops">
                      <div className="text-weight-semibold-4 homee devshops">PROGRAM</div>
                    </div>
                    <div className="margin-bottom margin-small">
                      <h2 className="heading-4">How does the program works?</h2>
                    </div>
                    <div className="margin-bottom margin-medium">
                      <p className="text-size-medium-5 homee devshop">
                        <strong>1. </strong>it&#x27;s very simple: you recommend the <strong>Bridgefy SDK</strong> to your mobile app customers.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <section className="section-layout210-3 devshop">
          <div className="page-padding-5 devshop">
            <div className="container-large-5">
              <div className="padding-vertical padding-xhuge">
                <div className="w-layout-grid layout210_component-2">
                  <div className="layout210_image-wrapper-2">
                    <img
                      src={devshops2}
                      loading="lazy"
                      alt=""/>
                  </div>
                  <div id="w-node-_68f0f4eb-ec21-efad-0377-10de0a4e81ae-35da6572" className="layout210_content">
                    <div className="margin-bottom margin-medium">
                      <p className="text-size-medium-5 homee devshop">
                        <strong>2. Get a % </strong>of the revenue that customer generates through the <strong>Bridgefy SDK</strong></p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </section>
      <div className="div-block-8"></div>
      <section id="revenue" className="section-layout210-4 devshop">
        <div className="page-padding-6">
          <div className="container-large">
            <div className="padding-vertical padding-xhuge">
              <div className="w-layout-grid layout210_component-2">
                <div className="layout210_image-wrapper-2">
                  <div id="w-node-_68f0f4eb-ec21-efad-0377-10de0a4e81bd-35da6572" className="layout210_content">
                    <div className="text-weight-semibold-5">FEATURES</div>
                    <div className="margin-bottom margin-small">
                      <h2 className="heading-4 homee">
                        <strong>Increase Revenue and Engagement</strong>
                      </h2>
                    </div>
                    <div className="margin-bottom margin-medium">
                      <p className="text-size-medium-5 homee">Making your app work without an Internet connection can increase engagement and revenue, while collecting KPI-driving data that were previously impossible to generate before. </p>
                    </div>
                    <div className="button-row-3">
                      <div className="button-wrapper">
                        <a href="#form" className="button-tertiary margin-left margin-xxsmall w-inline-block">
                          <div className="text-block-2">
                            <strong>Sign up to learn how!</strong>
                          </div>
                          <div className="button-tertiary_icon w-embed">
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M6 3L11 8L6 13" stroke="CurrentColor" strokeWidth="1.5"></path>
                            </svg>
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <img
                  src={revenue}
                  alt=""
                  data-w-id="68f0f4eb-ec21-efad-0377-10de0a4e81ce"
                  id="w-node-_68f0f4eb-ec21-efad-0377-10de0a4e81ce-35da6572"
                  loading="lazy"/>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="meshnet" className="section-layout210-4 s2 devshop">
        <div className="page-padding-6">
          <div className="container-large">
            <div bind="68f0f4eb-ec21-efad-0377-10de0a4e81d2" className="padding-vertical padding-xhuge">
              <div className="w-layout-grid layout210_component-2">
                <div className="layout210_image-wrapper-2">
                  <div id="w-node-_68f0f4eb-ec21-efad-0377-10de0a4e81d5-35da6572" className="layout210_content">
                    <div className="margin-bottom margin-small">
                      <h2 className="heading-4 homee">
                        <strong>Reach Untapped Markets</strong></h2>
                    </div>
                    <div className="margin-bottom margin-medium">
                      <p className="text-size-medium-5 homee">
                        There are billions of people around the world who still don’t have an Internet connection. Can you imagine what level your app could get to if you could reach them?
                      </p>
                    </div>
                    <div className="button-row-3">
                      <div className="button-wrapper">
                        <a href="#form" className="button-tertiary margin-left margin-xxsmall w-inline-block">
                          <div className="text-block-2">
                            <strong>Sign up to learn how!</strong></div>
                          <div className="button-tertiary_icon w-embed">
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M6 3L11 8L6 13" stroke="CurrentColor" strokeWidth="1.5"></path>
                            </svg>
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <img
                  src={frame2}
                  loading="lazy"
                  data-w-id="68f0f4eb-ec21-efad-0377-10de0a4e81e4"
                  id="w-node-_68f0f4eb-ec21-efad-0377-10de0a4e81e4-35da6572"
                  alt=""/>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        id="safeinfo"
        className="section-layout210-4 s2 devshop">
        <div className="page-padding-6">
          <div className="container-large">
            <div className="padding-vertical padding-xhuge">
              <div className="w-layout-grid layout210_component-2">
                <div className="layout210_image-wrapper-2">
                  <div id="w-node-_68f0f4eb-ec21-efad-0377-10de0a4e81eb-35da6572" className="layout210_content">
                    <div className="margin-bottom margin-small">
                      <h2 className="heading-4 homee">
                        <strong bind="68f0f4eb-ec21-efad-0377-10de0a4e81ee">Set Your App Apart from Your Competition</strong>
                      </h2>
                    </div>
                    <div className="margin-bottom margin-medium">
                      <p className="text-size-medium-5 homee">There are millions of apps available, and there are more each day. Give your market a reason to choose yours over your competitors’ by offering them something nobody else has.</p>
                    </div>
                    <div className="button-row-3">
                      <div className="button-wrapper">
                        <a href="#form" className="button-tertiary margin-left margin-xxsmall w-inline-block">
                          <div className="text-block-2">
                            <strong>Sign up to learn how!</strong></div>
                          <div className="button-tertiary_icon w-embed"><svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M6 3L11 8L6 13" stroke="CurrentColor" strokeWidth="1.5"></path>
                            </svg></div>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <img
                  src={frame1}
                  alt=""
                  data-w-id="68f0f4eb-ec21-efad-0377-10de0a4e81fa"
                  id="w-node-_68f0f4eb-ec21-efad-0377-10de0a4e81fa-35da6572"
                  loading="lazy"/>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="cases" className="section-layout89-2 devshop">
        <div className="page-padding-6 homeee">
          <div className="container-large">
            <div className="padding-vertical padding-xhuge">
              <div className="margin-bottom margin-xxlarge">
                <div className="w-layout-grid layout89_component-4">
                  <div className="layout89_content-left">
                    <div className="text-weight-semibold-5">COMPATIBILITY</div>
                    <h2 className="heading-9 cases">
                      <strong className="bold-text-6 devshops">
                        Messaging, Dating, Gaming, Events, School or Natural Disasters App? Then It Can Work Offline!
                      </strong>
                    </h2>
                  </div>
                  <div className="layout89_content-right">
                    <div className="margin-bottom margin-small devshops">
                      <p className="text-size-medium-5 homeeee _2 _3">The Bridgefy SDK can make a lot of different kinds of app work without Internet. Messaging, dating, gaming, events, school and natural disaster apps are especially compatible with our offline capabilities.</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="layout89_image-wrapper">
                <div className="columns-6 devshops w-row">
                  <div className="column-10 w-col w-col-3">
                    <img
                      src="https://uploads-ssl.webflow.com/62d5fdd7d8412d582512e77c/62d5fdd7d8412dbdc312e7a9_useCasesMusical.png"
                      loading="lazy"
                      data-w-id="68f0f4eb-ec21-efad-0377-10de0a4e820e"
                      alt=""
                      className="image-27"/>
                  </div>
                  <div className="column-9 w-col w-col-3">
                    <img
                      src={useCases5}
                      loading="lazy"
                      data-w-id="68f0f4eb-ec21-efad-0377-10de0a4e8210"
                      alt=""
                      className="image-27"/>
                  </div>
                  <div className="column-9 w-col w-col-3">
                    <img
                      src="https://uploads-ssl.webflow.com/62d5fdd7d8412d582512e77c/62d5fdd7d8412dcd5112e7a3_useCasesGaming.png"
                      loading="lazy"
                      data-w-id="68f0f4eb-ec21-efad-0377-10de0a4e8212"
                      alt=""
                      className="image-27"/>
                  </div>
                  <div className="column-6 w-col w-col-3">
                    <img
                      src={useCases6}
                      loading="lazy"
                      data-w-id="68f0f4eb-ec21-efad-0377-10de0a4e8214"
                      alt="" className="image-27"/>
                    </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section-contact12 form devshop">
        <section id="form" className="page-padding-2 sales saless">
          <div className="container-large">
            <div className="padding-vertical padding-xhuge">
              <div className="margin-bottom margin-large sales">
                <div className="max-width-large">
                  <div className="margin-bottom margin-small">
                    <div className="text-weight-semibold-5 dark home">SIGN UP!</div>
                    <h2 className="heading-7 sales"><strong bind="68f0f4eb-ec21-efad-0377-10de0a4e821f" className="bold-text-7">Amplify Your App’s Users and Keep Them Engaged when Other Apps Stop Working</strong></h2>
                    <p className="text-size-medium-5 homeeee _2 _5">Sign up to get the guide to learn how the Bridgefy SDK can make your app send offline messages and more!</p>
                  </div>
                </div>
                <div className="contact12_form-block-2 w-form">
                  <form
                    id="wf-form-Contact-12-Form"
                    name="wf-form-Contact-12-Form"
                    data-name="Contact 12 Form"
                    method="get"
                    bind="68f0f4eb-ec21-efad-0377-10de0a4e8224"
                    className="contact12_form">
                    <div className="form-field-2col">
                      <div className="form-field-wrapper">
                        <label htmlFor="First-name" className="field-label-2">First name</label>
                        <input
                          type="text"
                          className="select-field-2 w-input"
                          maxLength="256"
                          name="First-name"
                          data-name="First name"
                          placeholder=""
                          id="First-name"
                          required=""/>
                      </div>
                      <div className="form-field-wrapper">
                        <label
                          htmlFor="Last-name" className="field-label-2">Last name</label>
                        <input
                          type="text"
                          className="select-field-2 w-input"
                          maxLength="256"
                          name="Last-name"
                          data-name="Last name"
                          placeholder=""
                          id="Last-name"
                          required=""/>
                      </div>
                    </div>
                    <div className="form-field-2col is-mobile-1col">
                      <div className="form-field-wrapper">
                        <label htmlFor="Company-Email" className="field-label-2">Company Email</label>
                        <input
                          type="email"
                          className="select-field-2 w-input"
                          maxLength="256"
                          name="Company-Email"
                          data-name="Company Email"
                          placeholder=""
                          id="Company-Email"
                          required=""/>
                      </div>
                    </div>
                    <div
                      className="margin-bottom margin-xsmall">
                      <label
                        htmlFor="Contact-12-Select-4"
                        className="field-label-2 small terms lp devshops">
                          By clicking “Submit&quot; you accept our
                          <Link to="/terms-of-service" className="link-19">Terms of Service</Link> and
                          <Link to="/privacy-policy" className="link-19">Privacy Policy</Link>.  
                      </label>
                    </div>
                    <a href="#form" className="button-3 max-width-full red w-button"><strong bind="68f0f4eb-ec21-efad-0377-10de0a4e823d">Submit</strong></a>
                  </form>
                  <div className="success-message-2 w-form-done">
                    <div className="success-text">Thank you! Your submission has been received!</div>
                  </div>
                  <div className="error-message w-form-fail">
                    <div className="error-text">Oops! Something went wrong while submitting the form.</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </section>
    </>
  );
}
