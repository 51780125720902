import g1 from '../../shared/images/g1.png';
import g2 from '../../shared/images/g2.png';
import g3 from '../../shared/images/g3.png';
import useCaseSocial from '../../shared/images/useCasesSocial.png';
import useCaseChat from '../../shared/images/useCasesChat.png';
import useCasePay from '../../shared/images/useCasesPay.png';
import useCaseGaming from '../../shared/images/useCasesGaming.png';
import circle1 from '../../shared/images/Circle1.png';
import circle2 from '../../shared/images/Circle2.png';
import circle3 from '../../shared/images/Circle3.png';
import circle4 from '../../shared/images/Circle4.png';
import circle5 from '../../shared/images/Circle5.png';
import hare2 from '../../shared/images/Hare2.png';
import imgDashboard from '../../shared/images/img_dashboard.png';
import imgGitbook from '../../shared/images/img_gitbook2.png'
import { Link } from 'react-router-dom';
import { useState } from 'react';
import { Parallax, ParallaxProvider } from 'react-scroll-parallax';
import bluetoothBackground from "../../shared/images/bluetooth_background.png";

export default function SdkContent() {
  const [tabStat, setTabStat] = useState(1);

  function displayImage () {
    if (tabStat === 1) {
      return (
        <div className="layout219_image-wrapper">
          <img
            className="image-13"
            src={g1}
            alt=""
            loading="lazy"/>
        </div>)
    } else if (tabStat === 2) {
      return (
        <div className="layout219_image-wrapper">
          <img
            src={g2}
            loading="lazy"
            alt=""
            className="image-13"/>
        </div>)
    } else {
      return (
        <div className="layout219_image-wrapper">
          <img
            src={g3}
            loading="lazy"
            alt=""
            className="image-13"/>
        </div>)
    }
  }

  return (
      <>
      <ParallaxProvider>
    <section className="section-layout219">
      <div className="page-padding sdkweb">
        <div className="container-large">
          <div className="padding-vertical padding-xhuge">
            <div className="text-weight-semibold dark home">THE BRIDGEFY</div>
            <h2 className="heading-2">A mesh network SDK to make apps work without the Internet</h2>
            <p className="paragraph-2">Add the Bridgefy SDK to your mobile app’s code and enable users to share content over powerful, Bluetooth mesh networks. Decentralized, private, and efficient.</p>
            <div data-duration-in="100" data-duration-out="100" data-current="Tab 1" data-easing="ease" className="layout219_component w-tabs">
              <div id="w-node-ed968b55-f36c-1006-74f9-414addf3d73e-0629d905" className="layout219_tabs-menu w-tab-menu">
                <div
                  className={'layout219_tabs-link w-inline-block w-tab-link' + (tabStat === 1 ? ' w--current' : '')}
                  onClick={() => setTabStat(1)}>
                  <div className="margin-bottom margin-xsmall">
                    <h3 className="heading-medium feat sdkweb">Bluetooth mesh networks</h3>
                  </div>
                  <p className="paragraph-2">Devices running your app create Bluetooth Low-Energy mesh networks, which work in large crowds, during and after natural disasters, and whenever else your users lose access to the Internet.</p>
                </div>
                <div
                  className={'layout219_tabs-link w-inline-block w-tab-link' + (tabStat === 2 ? ' w--current' : '')}
                  onClick={() => setTabStat(2)}>
                  <div className="margin-bottom margin-xsmall">
                    <h3 className="heading-medium feat sdkweb">Smart and Quick Distribution</h3>
                  </div>
                  <p className="paragraph _4">The Bridgefy algorithm makes sure information is distributed using the broadcast method, which increases the chances of content being delivered successfully, as opposed to the shortest-route method, which is fragile and unstable.</p>
                </div>
                <div
                  className={'layout219_tabs-link w-inline-block w-tab-link' + (tabStat === 3 ? ' w--current' : '')}
                  onClick={() => setTabStat(3)}>
                  <div className="margin-bottom margin-xsmall">
                    <h3 className="heading-medium feat sdkweb">Seamless and Resource-efficient</h3>
                  </div>
                  <p className="paragraph-3">All of your users participate in the mesh networks without having to provide any extra permissions, perform any actions, or even be aware of participating in traffic. Battery, storage, and processor are all taken into consideration, and used the least amount possible to reduce impact.</p>
                </div>
              </div>
              <div className="w-tab-content">
              <div className="w-tab-pane w--tab-active">
                {
                  displayImage()
                }
              </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className="section-layout89 sdkweb">
      <div className="page-padding homeee">
        <div className="container-large">
          <div className="padding-vertical padding-xhuge">
            <div className="margin-bottom margin-xxlarge">
              <div className="w-layout-grid layout89_component">
                <div className="layout89_content-left">
                  <div className="margin-bottom margin-xsmall sdkweb">
                    <div className="text-weight-semibold sdkweb">USE CASES</div>
                  </div>
                  <h2 className="heading-2 cases">One SDK,<br/>many use cases.</h2>
                </div>
                <div className="layout89_content-right">
                  <div className="container-2 w-container">
                    <p id="w-node-_165620b3-cc70-e20a-3713-11c32790b195-0629d905">The Bridgefy SDK is as versatile as you need it to be. Hundreds of millions of people lose access to the Internet every day, but they still need to use mobile apps. Open your mobile app to immense new markets that are waiting to use gaming, messaging, education, and payments apps, but can’t because of lack of an Internet connection.</p>
                    <Link to="/use-cases" className="w-inline-block">
                      <div className="text-span-5 bold-text-5">Learn More &gt;</div>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="wf-section">
              <div className="columns-4 w-row">
                <div className="column-6 w-col w-col-3">
                  <div className="text-weight-semibold cases">SOCIAL MEDIA</div>
                  <p className="paragraph-3 cases"> Power your messaging, dating, or classNameroom app to work when others don’t.</p>
                  <Parallax translateY={[15, -20]}>
                  <img
                    src={useCaseSocial}
                    loading="lazy"
                    data-w-id="9b0b41f4-bc40-0506-95cf-ae2714811b2f"
                    alt=""
                    className="image-2 imgsocial"/>
                  </Parallax>
                </div>
                <div className="column-7 w-col w-col-3">
                  <div className="text-weight-semibold cases">MUSIC AND SPORTS EVENTS</div>
                  <p className="paragraph-3 cases"> Keep users connected to each other while at a large concert or stadium, they’ll thank you for it.</p>
                  <Parallax translateY={[15, -20]}>
                  <img
                    src={useCaseChat}
                    loading="lazy"
                    data-w-id="9b0b41f4-bc40-0506-95cf-ae2714811b37"
                    alt=""
                    className="image-2 imgmusic"/>
                  </Parallax>
                </div>
                <div className="column-8 w-col w-col-3">
                  <div className="text-weight-semibold cases">PAYMENTS</div>
                  <p className="paragraph-3 cases">Person-to-person payments are now a possibility in large events, rural locations, and for tourists without a data plan.</p>
                  <Parallax translateY={[15, -20]}>
                  <img
                    src={useCasePay}
                    loading="lazy"
                    data-w-id="9b0b41f4-bc40-0506-95cf-ae2714811b3f"
                    alt=""
                    className="image-2 imgpay"/>
                  </Parallax>
                </div>
                <div className="column-9 w-col w-col-3">
                  <div className="text-weight-semibold cases">GAMING</div>
                  <p className="paragraph-3 cases">Games like Pokémon Go, Chess, Magic The Gathering, and many more are more fun if you’re not using up your data plan. Kids around the world will be thrilled!</p>
                  <Parallax translateY={[15, -20]}>
                  <img
                    src={useCaseGaming}
                    loading="lazy"
                    data-w-id="9b0b41f4-bc40-0506-95cf-ae2714811b45"
                    alt=""
                    className="image-2"/>
                  </Parallax>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className="section-cta26 midsect">
      <div className="page-padding">
        <div className="container-small">
          <div className="padding-vertical padding-xhuge">
            <div className="text-align-center">
              <div className="margin-bottom margin-small">
                <div className="text-weight-semibold connecting">OUR PHILOSOPHY</div>
                <h2 className="heading-3">9.6 million people have trusted Bridgefy</h2>
              </div>
              <div className="margin-bottom margin-medium">
                <p className="text-size-medium sdkweb center">Millions have already benefited from staying connected when it matters the most. Join the largest-growing mesh network in the world, and watch your mobile app flourish!</p>
              </div>
              <a
                href="https://developer.bridgefy.me"
                target="_blank"
                rel="noreferrer"
                className="button-2 max-width-full red pholosophy sdkweb centered w-button">
                  Sign Up
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="w-container">
        <img
          src={circle5}
          loading="lazy"
          data-w-id="66aa13d9-cfc8-f510-930e-72cecc67ac64"
          alt=""
          className="image"
          data-img="in357a423a"
        />
        <img
          src={circle1}
          loading="lazy"
          data-w-id="4b666b6e-f606-2f45-4332-f9a8885791da"
          sizes="40vw"
          alt=""
          className="image-6"
          data-img="in35b2a03e"
        />
        <img
          src={circle2}
          loading="lazy"
          data-w-id="19e1eb2b-b681-da42-b645-3a35103ede29"
          sizes="(max-width: 627px) 100vw, 627px"
          bind="19e1eb2b-b681-da42-b645-3a35103ede29"
          alt=""
          className="image-7"/>
        <img
          src={circle4}
          loading="lazy"
          data-w-id="932a19bb-f1b4-964d-4fae-0dd4abec32dc"
          sizes="(max-width: 991px) 100vw, 36vw"
          alt=""
          className="image-4"/>
        <img
          src={circle3}
          data-w-id="bccda35a-8f3b-dde6-f847-c0f31e24d66e"
          sizes="(max-width: 991px) 100vw, 780px"
          alt=""
          className="image-5"/>
        <img
          src={hare2}
          loading="lazy"
          sizes="(max-width: 479px) 70vw, (max-width: 767px) 40vw, (max-width: 991px) 30vw, (max-width: 1919px) 18vw, 20vw"
          alt=""
          className="image-12"/>
        </div>
    </section>
    <section bind="09d6c5ec-7e56-f11d-8a9d-2623c29179bb" className="section-layout210 lastsect">
      <div bind="09d6c5ec-7e56-f11d-8a9d-2623c29179bc" className="page-padding">
        <div bind="09d6c5ec-7e56-f11d-8a9d-2623c29179bd" className="container-large">
          <div bind="09d6c5ec-7e56-f11d-8a9d-2623c29179be" className="padding-vertical padding-xhuge"></div>
        </div>
      </div>
      <div className="w-layout-grid layout210_component home homedivs">
        <div className="layout210_image-wrapper">
          <img
            loading="lazy"
            sizes="(max-width: 479px) 92vw, (max-width: 932px) 95vw, (max-width: 991px) 886px, (max-width: 1919px) 42vw, 46vw"
            src={imgDashboard}
            alt=""/>
          </div>
        <div id="w-node-_9e9163ae-6171-affc-0eee-a9fbf46e7699-0629d905" className="layout210_content">
          <div className="margin-bottom margin-xsmall sdkweb">
            <div className="text-weight-semibold homee"><strong>NEW BRIDGEFY DASHBOARD</strong></div>
          </div>
          <div className="margin-bottom margin-small">
            <h2 className="heading-4 homee">Analytics like you’ve literally never seen before</h2>
          </div>
          <div className="margin-bottom margin-medium">
            <div className="text-size-medium homee w-richtext">
              <p>You don’t know how people use your app during an 80,000-person concert or after an earthquake, because they can’t use it without the Internet. Once you integrate the Bridgefy SDK, you’ll be able to track activity like never before.</p>
            </div>
            <div className="margin-bottom margin-small">
              <ul className="layout210_list home">
                <li className="layout210_item">Message metrics</li>
                <li className="layout210_item">User interactions</li>
                <li className="layout210_item">Device analytics</li>
              </ul>
            </div>
          </div>
          <div className="button-row">
            <div className="button-wrapper">
              <a
                href="https://developer.bridgefy.me"
                target="_blank"
                rel="noreferrer"
                className="button-tertiary margin-left margin-xxsmall w-inline-block">
                <div className="text-block-2">Get Started</div>
                <div className="button-tertiary_icon w-embed"><svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M6 3L11 8L6 13" stroke="CurrentColor" strokeWidth="1.5"></path>
                  </svg></div>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="w-layout-grid layout210_component home homedivs">
        <div id="w-node-d8631139-18fe-6bea-fcd1-d174234d1022-0629d905" className="layout210_image-wrapper">
          <img
            loading="lazy"
            sizes="(max-width: 479px) 92vw, (max-width: 932px) 95vw, (max-width: 991px) 886px, (max-width: 1919px) 42vw, 46vw"
            src={imgGitbook}
            alt=""/>
        </div>
        <div bd="w-node-d8631139-18fe-6bea-fcd1-d174234d1024-0629d905" className="layout210_content">
          <div className="margin-bottom margin-xsmall sdkweb">
            <div className="text-weight-semibold homee">
              <strong>DOCUMENTATION</strong></div>
          </div>
          <div className="margin-bottom margin-small">
            <h2 className="heading-4 homee">Multiple SDKs with full documentation</h2>
          </div>
          <div className="margin-bottom margin-medium">
            <div className="text-size-medium homee w-richtext">
              <p>Bridgefy has a cross platform library available for iOS, Android, React Native, and Flutter!</p>
            </div>
          </div>
          <div className="button-row">
            <div className="button-wrapper">
              <a
                href="https://bridgefy.gitbook.io/sdk/"
                target="_blank"
                rel="noreferrer"
                className="button-tertiary margin-left margin-xxsmall w-inline-block">
                <div className="text-block-2">Browse the Docs</div>
                <div className="button-tertiary_icon w-embed"><svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M6 3L11 8L6 13" stroke="CurrentColor" strokeWidth="1.5"></path>
                  </svg></div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className="section-layout192">
      <div className="page-padding">
        <div className="container-large">
          <div className="padding-vertical padding-xhuge"></div>
        </div>
      </div>
    </section>
      </ParallaxProvider>
      </>
  );
}
