import phone2 from '../../shared/images/phone2.png'
import bridgefyMessengerApp2 from '../../shared/images/6266b1aa800e592a99b17884_BRIDGEFY-MESSENGER-APP2_.png';
import apple from '../../shared/images/apple.png';
import google from '../../shared/images/google.png';
import step1 from '../../shared/images/step1.png';
import step2 from '../../shared/images/step2.png';
import step3 from '../../shared/images/step3.png';
import g1 from '../../shared/images/g1.png';
import bluetoothBackground from '../../shared/images/bluetooth_background.png';
import { Parallax, ParallaxProvider } from 'react-scroll-parallax';

export default function HomeContent() {
  return (
    <ParallaxProvider>
      <div bind="4ef18d40-b284-acbc-8d93-54a32f4479ea" className="page-padding-4 first hero">
        <div
          bind="4ef18d40-b284-acbc-8d93-54a32f4479eb"
          className="padding-vertical padding-xhuge">
          <div
            bind="4ef18d40-b284-acbc-8d93-54a32f4479ec"
            style={{zIndex: 2, position: 'relative'}}
            className="w-layout-grid header2_component">
            <Parallax translateY={[-5, 18]} style={{backgroundImage: bluetoothBackground}}>
              <img
                className="image-21"
                src={phone2}
                alt=""
                data-w-id="4ef18d40-b284-acbc-8d93-54a32f4479ed"
                id="w-node-_4ef18d40-b284-acbc-8d93-54a32f4479ed-7529f769"
                loading="lazy"
              />
            </Parallax>
            <div
              bind="4ef18d40-b284-acbc-8d93-54a32f4479ee"
              className="header2_content">
                <img
                  src={bridgefyMessengerApp2}
                  loading="lazy"
                  width="766"
                  sizes="(max-width: 479px) 100vw, (max-width: 991px) 90vw, (max-width: 1919px) 68vw, 766px"
                  bind="4ef18d40-b284-acbc-8d93-54a32f4479ef"
                  alt=""
                  className="image-20"/>
              <div bind="4ef18d40-b284-acbc-8d93-54a32f4479f0" className="margin-bottom margin-small">
                <h1 bind="4ef18d40-b284-acbc-8d93-54a32f4479f1" className="heading-8">Offline Messaging</h1>
              </div>
              <div bind="4ef18d40-b284-acbc-8d93-54a32f4479f3" className="margin-bottom margin-medium">
                <p bind="4ef18d40-b284-acbc-8d93-54a32f4479f4" className="text-size-medium-4 hero bigger">
                  Bridgefy is a free messaging app that works without the Internet. Perfect for natural disasters, large events, and at school!
                </p>
              </div>
              <a
                bind="4ef18d40-b284-acbc-8d93-54a32f4479f6"
                id="w-node-_4ef18d40-b284-acbc-8d93-54a32f4479f6-7529f769"
                href="https://apps.apple.com/us/app/bridgefy/id975776347/?utm_source=bridgefy-website&amp;utm_medium=website-ios&amp;utm_campaign=home-ios&amp;utm_content=app-store"
                target="_blank"
                rel="noreferrer"
                className="link-block w-inline-block">
                <img
                  bind="4ef18d40-b284-acbc-8d93-54a32f4479f7"
                  loading="lazy"
                  src={apple}
                  alt=""/>
              </a>
              <a
                bind="4ef18d40-b284-acbc-8d93-54a32f4479f8"
                id="w-node-_4ef18d40-b284-acbc-8d93-54a32f4479f8-7529f769"
                href="https://play.google.com/store/apps/details?id=me.bridgefy.main&amp;referrer=utm_source=bridgefy&amp;utm_medium=website&amp;utm_term=demo-app&amp;utm_content=google-play"
                target="_blank"
                rel="noreferrer"
                className="w-inline-block">
                <img
                  bind="4ef18d40-b284-acbc-8d93-54a32f4479f9"
                  loading="lazy"
                  src={google}
                  alt=""
                  className="image-19"/>
              </a>
            </div>
          </div>
          <img
            src={bluetoothBackground}
            loading="lazy"
            width="1106"
            alt=""
            className="image-22 blue"/>
        </div>
      </div>
      <div bind="d03f3a6b-45df-8f16-a625-8a520a6a93fd" className="page-padding-5 homeee">
        <div bind="d03f3a6b-45df-8f16-a625-8a520a6a93fe" className="container-large-2">
          <div bind="d03f3a6b-45df-8f16-a625-8a520a6a93ff" className="padding-vertical padding-xhuge">
            <div bind="d03f3a6b-45df-8f16-a625-8a520a6a9400" className="margin-bottom margin-xxlarge">
              <div bind="d03f3a6b-45df-8f16-a625-8a520a6a9401" className="w-layout-grid layout89_component-2">
                <div bind="d03f3a6b-45df-8f16-a625-8a520a6a9402" className="layout89_content-left-2">
                  <div bind="d03f3a6b-45df-8f16-a625-8a520a6a9403" className="text-weight-semibold-4">GETTING STARTED</div>
                  <h2 bind="d03f3a6b-45df-8f16-a625-8a520a6a9405" className="heading-9 cases">
                    How to use Bridgefy
                  </h2>
                </div>
              </div>
            </div>
            <div bind="d03f3a6b-45df-8f16-a625-8a520a6a9407" className="layout89_image-wrapper">
              <div bind="d03f3a6b-45df-8f16-a625-8a520a6a9408" className="columns-6 w-row">
                <div bind="d03f3a6b-45df-8f16-a625-8a520a6a9409" className="w-col w-col-4">
                  <div bind="d03f3a6b-45df-8f16-a625-8a520a6a940a" className="text-weight-semibold-4 cases">
                    01. <strong bind="d03f3a6b-45df-8f16-a625-8a520a6a940c">Make sure you and your friends download Bridgefy.</strong>
                  </div>
                  <Parallax translateY={[-3, 6]}>
                    <img
                      src={step1}
                      loading="lazy"
                      data-w-id="d03f3a6b-45df-8f16-a625-8a520a6a940e"
                      sizes="(max-width: 479px) 86vw, (max-width: 767px) 87vw, (max-width: 1919px) 28vw, 406.65625px"
                      bind="d03f3a6b-45df-8f16-a625-8a520a6a940e"
                      alt=""
                      className="image-23 align"/>
                  </Parallax>
                </div>
                <div bind="d03f3a6b-45df-8f16-a625-8a520a6a940f" className="w-col w-col-4">
                  <div bind="d03f3a6b-45df-8f16-a625-8a520a6a9410" className="text-weight-semibold-4 cases">
                    02. <strong bind="d03f3a6b-45df-8f16-a625-8a520a6a9412">Turn on Bluetooth on your phone.<br/>‍</strong>
                  </div>
                  <Parallax translateY={[-3, 6]}>
                    <img
                      src={step2}
                      loading="lazy"
                      data-w-id="d03f3a6b-45df-8f16-a625-8a520a6a9416"
                      sizes="(max-width: 479px) 86vw, (max-width: 767px) 87vw, (max-width: 1919px) 28vw, 406.65625px"
                      bind="d03f3a6b-45df-8f16-a625-8a520a6a9416"
                      alt=""
                      className="image-23"/>
                  </Parallax>
                </div>
                <div bind="d03f3a6b-45df-8f16-a625-8a520a6a9417" className="w-col w-col-4">
                  <div bind="d03f3a6b-45df-8f16-a625-8a520a6a9418" className="text-weight-semibold-4 cases">
                    03. <strong bind="d03f3a6b-45df-8f16-a625-8a520a6a941a">Share messages with friends that are within 100 mts. / 330 ft. from you.</strong>
                  </div>
                  <Parallax translateY={[-3, 6]}>
                    <img
                      src={step3}
                      loading="lazy"
                      data-w-id="d03f3a6b-45df-8f16-a625-8a520a6a941c"
                      sizes="(max-width: 479px) 86vw, (max-width: 767px) 87vw, (max-width: 1919px) 28vw, 406.65625px"
                      bind="d03f3a6b-45df-8f16-a625-8a520a6a941c"
                      alt=""
                      className="image-23"/>
                  </Parallax>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section bind="9f872043-ab9a-a784-79da-7ed2b564818f" className="section-layout210-2">
        <div bind="9f872043-ab9a-a784-79da-7ed2b5648190" className="page-padding-5">
          <div bind="9f872043-ab9a-a784-79da-7ed2b5648191" className="container-large-2">
            <div bind="9f872043-ab9a-a784-79da-7ed2b5648192" className="padding-vertical padding-xhuge">
              <div bind="9f872043-ab9a-a784-79da-7ed2b5648193" className="w-layout-grid layout210_component-2">
                <div bind="9f872043-ab9a-a784-79da-7ed2b5648194" className="layout210_image-wrapper">
                  <Parallax translateY={[-3, 6]}>
                    <img
                      src={g1}
                      loading="lazy"
                      data-w-id="9f872043-ab9a-a784-79da-7ed2b5648195"
                      sizes="(max-width: 991px) 90vw, (max-width: 1919px) 41vw, 600px"
                      bind="9f872043-ab9a-a784-79da-7ed2b5648195"
                      alt=""/>
                  </Parallax>
                </div>
                <div bind="9f872043-ab9a-a784-79da-7ed2b5648196" id="w-node-_9f872043-ab9a-a784-79da-7ed2b5648196-7529f769" className="layout210_content">
                  <div bind="9f872043-ab9a-a784-79da-7ed2b5648197" className="margin-bottom margin-xsmall sdkweb">
                    <div bind="9f872043-ab9a-a784-79da-7ed2b5648198" className="text-weight-semibold-4 homee">THE TECH</div>
                  </div>
                  <div bind="9f872043-ab9a-a784-79da-7ed2b564819a" className="margin-bottom margin-small">
                    <h2 bind="9f872043-ab9a-a784-79da-7ed2b564819b" className="heading-4">How does Bridgefy Work?</h2>
                  </div>
                  <div bind="9f872043-ab9a-a784-79da-7ed2b564819d" className="margin-bottom margin-medium">
                    <p bind="9f872043-ab9a-a784-79da-7ed2b564819e" className="text-size-medium-4 homee work">Bridgefy uses Bluetooth to send messages from your phone to your friends’, over a distance of 100 mts. / 330 ft. (a whole football field!). If you need to text someone that is more than 100 mts. / 330 ft. away, your message hops on other Bridgefy users’ phones until it reaches its destination!</p>
                    <div bind="9f872043-ab9a-a784-79da-7ed2b56481a0" className="text-weight-semibold-4 homee">MULTIPLE USES</div>
                    <p bind="9f872043-ab9a-a784-79da-7ed2b56481a2" className="text-size-medium-4 homee uses">We almost never know when we’re going to find ourselves without Internet access. Whether it’s a large event, a school, traveling abroad, rural location, a natural disaster, or a protest, Bridgefy’s got your back. Don’t wait until it’s too late to download it!</p>
                  </div>
                  <div bind="9f872043-ab9a-a784-79da-7ed2b56481a4" className="button-row-3">
                    <div bind="9f872043-ab9a-a784-79da-7ed2b56481a5" className="button-wrapper">
                      <a
                        bind="9f872043-ab9a-a784-79da-7ed2b56481a6"
                        href="https://bridgefy.me/how-does-the-bridgefy-app-work/"
                        target="_blank"
                        rel="noreferrer"
                        className="button-tertiary margin-left margin-xxsmall w-inline-block">
                        <div bind="9f872043-ab9a-a784-79da-7ed2b56481a7" className="text-block-2">Learn More</div>
                        <div bind="9f872043-ab9a-a784-79da-7ed2b56481a9" className="button-tertiary_icon w-embed"><svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M6 3L11 8L6 13" stroke="CurrentColor" strokeWidth="1.5"></path>
                          </svg></div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </ParallaxProvider>
  );
}
