import SdkFooter from "../../shared/modules/SdkFooter/SdkFooter";
import SdkNavbar from "../../shared/modules/SdkNavbar/SdkNavbar";
import PrivacyPolicyContent from "./PrivacyPolicyContent";

export default function PrivacyPolicy () {
  return (
    <div className="body-2">
      <SdkNavbar></SdkNavbar>
      <PrivacyPolicyContent></PrivacyPolicyContent>
      <SdkFooter></SdkFooter>
    </div>
  );
}