
import sdk3 from '../../shared/images/SDK3_.png';
import bahreTest from '../../shared/images/BhareTest.png';
import bluetoothBackground from '../../shared/images/bluetooth_background.png';
import { Parallax, ParallaxProvider } from 'react-scroll-parallax';

export default function HeroSdk() {
  return (
    <>
      <div className="page-padding divvvv">
          <div className="container-large">
            <div className="padding-vertical padding-xhuge">
              <div className="w-layout-grid header2_component">
                <img
                  src={bahreTest}
                  loading="lazy"
                  id="w-node-_1cc70cec-b878-df27-ac99-db8ae26c61cf-0629d905"
                  sizes="(max-width: 991px) 100vw, (max-width: 1919px) 51vw, 729.59375px"
                  bind="1cc70cec-b878-df27-ac99-db8ae26c61cf"
                  alt="" className="image-10"/>
                <div className="header2_content">
                  <img
                    className="image-8"
                    src={sdk3}
                    width="766"
                    alt=""
                    sizes="(max-width: 991px) 90vw, (max-width: 1919px) 68vw, 766px"
                    loading="lazy"/>
                  <div className="margin-bottom margin-small">
                    <h1 className="heading">Make your mobile app work without the Internet</h1>
                  </div>
                  <div className="margin-bottom margin-medium">
                    <p className="text-size-medium hero bigger sdkweb">Reach new markets, differentiate your products from competitors’, and watch revenue skyrocket by letting your users engage with your app no matter what.</p>
                  </div>
                  <div className="columns-5 w-row">
                    <div className="w-col w-col-6">
                      <a
                        id="w-node-_73a44653-3a27-1188-d24c-cb899907e742-0629d905"
                        href="https://developer.bridgefy.me"
                        target="_blank"
                        rel="noreferrer"
                        className="button-2 max-width-full red home splashpage w-button">
                        Sign Up
                      </a>
                    </div>
                    <div className="column-5 w-col w-col-6">
                      <a
                        href="https://docs.google.com/document/d/1CYXBce_P1PapqFfMRWS1PnN3IWvKJgwVyleF757Ka8E/edit?usp=sharing"
                        target="_blank"
                        rel="noreferrer"
                        className="button-2 max-width-full red home splashpage blue w-button">
                        Read the Technical Whitepaper
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <img
              src={bluetoothBackground}
              loading="lazy"
              width="766"
              sizes="(max-width: 991px) 100vw, 766px"
              alt=""
              className="image-8 blue"/>
          </div>
        </div>
    </>
  );
}
