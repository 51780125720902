import { useState } from 'react';
import { Link } from 'react-router-dom';
import BridgefyLogo from '../../images/BridgefyLogo1.svg';

export default function AppNavbar() {

  const [active, setActive] = useState(false);

  return(
    <div
      data-animation="default"
      className={'navbar1_component home donate w-nav app-navbar ' + (active ? ' open': '')}
      data-easing2="ease"
      data-easing="ease"
      data-collapse="medium"
      data-w-id="304f5298-9b37-4684-b9f9-d8dcf084f874"
      role="banner"
      data-duration="400"
      bind="304f5298-9b37-4684-b9f9-d8dcf084f874"
      style={{backgroundColor: 'transparent', position: 'relative', zIndex: 3}}>
    <div bind="304f5298-9b37-4684-b9f9-d8dcf084f875" className="navbar1_container home">
      <a href="/" bind="304f5298-9b37-4684-b9f9-d8dcf084f876" aria-current="page" className="navbar1_logo-link w-nav-brand w--current">
        <img
          src={BridgefyLogo}
          loading="lazy"
          width="150"
          bind="304f5298-9b37-4684-b9f9-d8dcf084f877"
          alt=""/>
          </a>
      <nav role="navigation" bind="304f5298-9b37-4684-b9f9-d8dcf084f878" className="navbar1_menu-2 home w-nav-menu">
        <a
          href="https://staging.bridgefy.me/blog/"
          bind="304f5298-9b37-4684-b9f9-d8dcf084f879"
          target="_blank"
          rel="noreferrer"
          className="navbar1_link-2 home donate w-nav-link">
            BLOG
        </a>
        <div bind="304f5298-9b37-4684-b9f9-d8dcf084f87b" className="navbar1_menu-buttons">
          <div bind="304f5298-9b37-4684-b9f9-d8dcf084f87c" className="navbar1_button-wrapper">
            <Link
              to="/"
              bind="304f5298-9b37-4684-b9f9-d8dcf084f87d"
              className="button-3 max-width-full red home w-button">
                Bridgefy SDK
            </Link>
          </div>
        </div>
      </nav>
      <div
        className={'navbar1_menu-button w-nav-button' +
        (active ? ' active-menu' : '')}
        onClick={() => setActive(!active)}>
        <div className="menu-icon1">
          <div className="menu-icon1_line-top"></div>
          <div className="menu-icon1_line-middle">
            <div className="menu-icon_line-middle-inner"></div>
          </div>
          <div className="menu-icon1_line-bottom"></div>
        </div>
      </div>
    </div>
    {
      !active ||  <div
      className="w-nav-overlay responsive-navbar"
      data-wf-ignore=""
      id="w-nav-overlay-0" style={{height: '5841.73px', display: 'block'}}>
      <nav
        role="navigation"
        className="navbar1_menu-2 home w-nav-menu"
        data-nav-menu-open=""
        style={{transform: 'translateY(0px)', transition: 'transform 400ms ease 0s'}}>
        <a
          href="https://staging.bridgefy.me/blog/"
          target="_blank"
          rel="noreferrer"
          className="navbar1_link-2 home donate w-nav-link w--nav-link-open">
          BLOG
        </a>
        <div className="navbar1_menu-buttons">
          <div className="navbar1_button-wrapper">
            <Link className="button-3 max-width-full red home w-button" to="/">
              Bridgefy SDK
            </Link>
          </div>
        </div>
      </nav>
    </div>
    }

  </div>
  );
}
