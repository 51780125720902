import SdkFooter from "../../shared/modules/SdkFooter/SdkFooter";
import SdkNavbar from "../../shared/modules/SdkNavbar/SdkNavbar";
import NotFoundContent from "./NotFoundContent";

export function NotFound() {
  return (
    <div className="body-3 not-found-container">
      <SdkNavbar></SdkNavbar>
      <NotFoundContent></NotFoundContent>
      <SdkFooter></SdkFooter>
    </div>
  );
}