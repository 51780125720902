import SdkFooter from "../../shared/modules/SdkFooter/SdkFooter";
import DevshopsContent from "./DevshopsContent";
import DevshopsNavbar from "./DevshopsNavbar";

export default function Devshops() {
  return (
    <div className="div-2 body-5">
      <DevshopsNavbar></DevshopsNavbar>
      <DevshopsContent></DevshopsContent>
      <SdkFooter notMarginTop={true}></SdkFooter>
    </div>
  );
}
