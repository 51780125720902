export const SDK_NAVBAR_DATA = [
  {
    title: 'Home',
    link: '/',
    external: false,
    id: 1
  },
  {
    title: 'Use cases',
    link: '/use-cases',
    external: false,
    id: 2
  },
  {
    title: 'faq',
    link: '/faq',
    external: false,
    id: 3
  },
  {
    title: 'blog',
    link: 'https://staging.bridgefy.me/blog/',
    external: true,
    id: 4
  },
  {
    title: 'App',
    link: '/app',
    external: false,
    id: 5
  }
];
