export const APP_FAQ_DATA = [
  {
    id: 1,
    question: 'How does the Bridgefy chat app work?',
    answer: `
      The Bridgefy app works thanks to the
      <a href="https://bridgefy.me/sdk/" target="_blank" class="link-31">Bridgefy SDK</a>
      <strong>, </strong>which is basically software that makes any mobile app work
      without the Internet. Instead of using Internet or phone services (like SMS), the Bridgefy technology runs on Bluetooth
      Low-Energy. This means that it connects devices 100% without Internet, and can cover large distances.To learn more about
      the specific ways the Bridgefy App works, please read our blog article
      <a href="https://bridgefy.me/how-to-use-the-bridgefy-offline-messaging-app/" target="_blank" className="link-19">
      How To Use The Bridgefy Offline Messaging App</a><strong>. </strong>You’ll be able to see illustrated
      examples of how the Bridgefy app has helped millions of people connect during concerts, festivals, sports events, natural disasters, and more!</div>
    `
  },
  {
    id: 2,
    question: 'Where can I download the Bridgefy app, and how do I start using the Bridgefy messaging app?',
    answer: `
      You can download the Bridgefy app on this page, or by simply looking for it on your phone’s app store,
      searching “Bridgefy” and downloading the Bridgefy chat app!Using the app is very simple. Once you’ve
      downloaded it on your Android or iOS device, make sure of the following: You have Internet access the very
      first time you open the app. This lets us activate the magic behind the app, a.k.a. the
      <a href="https://bridgefy.me/sdk/" target="_blank" className="link-32">Bridgefy SDK</a>.
      Once you’ve done this the very first time, you won’t need the Internet to use the Bridgefy app ever again!
      Bluetooth is turned on on your device. You have the latest version of the Bridgefy appRemember that you’ll
      then be able to text other Bridgefy users that are within 330 feet* from you!
      <strong>**Please DO NOT create an account on www.bridgefy.me if you’re
      trying to use the messaging app; creating an account is for Bridgefy SDK developer access only.**</strong>*This is an
      approximate based on testing and Bluetooth specifications. Distance can vary depending on physical conditions, hardware devices,
      and other potential external factors</div>
    `
  },
  {
    id: 3,
    question: 'Why is my friend not receiving my messages?',
    answer: `
      To make sure someone else can receive your Bridgefy messages, check that the following are true:That person is
      within 330 feet* of youYou both have the latest version of the Bridgefy appYou both have Bluetooth turned on.
      You both already opened Bridgefy while having access to the Internet at least once. There isn’t anything between
      you providing significant interference, such as large buildings *This is an approximate based on testing and
      Bluetooth specifications. Distance can vary depending on physical conditions, hardware devices, and other
      potential external factors.
    `
  },
  {
    id: 4,
    question: 'Is the Bridgefy App safe to use?',
    answer: `
      Yes! We use the Signal Protocol, which is industry-leading encryption that allows you to share
      Bridgefy messages safely and privately. Please keep in mind that messages sent in the Broadcast section of the
      Bridgefy App, can be seen by all other Bridgefy users around you.
    `
  },
  {
    id: 5,
    question: 'Why do you ask for access to my location services? (Android only)',
    answer: `
      Android users will see a prompt pop up when they’re first using the Bridgefy app, which asks for access to
      location permissions. This does not mean that we’re going to be tracking or using your location, it just means
      that the Android operating system requires it from us app developers that need to use the Bluetooth antenna on your phone.
    `
  },
  {
    id: 6,
    question: 'Does Bridgefy work on my iPad?',
    answer: `
    Yes! Bridgefy works on any iOS device that runs at least iOS 10, and has the latest version of the Bridgefy app. iPhones,
    iPads, iPads Mini, and iPads Pro can all text each other without Internet using the Bridgefy app!
    `
  }
];

export const FAQS_SECTION = [
  {
    id: 1,
    question: 'Should I integrate the Bridgefy SDK into my mobile app?',
    answer: `
      The Bridgefy SDK is a highly-versatile tool, that can solve problems for all kinds of companies.
      Some kinds of apps that can benefit from working without Internet are:<br/>‍<br/>
      <strong class="bold-text">
      · Games<br/>
      · Social media<br/>
      · Natural disaster<br/>
      · Large events (mßusical, sports)<br/>
      · Education<br/>‍</strong><br/>
      And much more!<br/>
      Not all apps are compatible with the Bridgefy SDK. For example, an app that is made for people to
      communicate across very large distances, might not benefit from the Bridgefy SDK. Another example is
      an app that focuses on hosting things on the cloud, such as YouTube or Dropbox.
      <a
        href="https://forms.gle/zeRenhmufMs7nPNB9"
        target="_blank"
        class="link-14">
          Please fill out this 4-question form to find out
      </a>!
    `
  },
  {
    id: 2,
    question: 'What&#x27;s the difference between the Bridgefy SDK and the Bridgefy Messaging App?',
    answer: `
      It might be confusing to hear about both the Bridgefy SDK and the Bridgefy app, but the differences are easy to
      understand:<br/><br/>
      <span class="text-span-3">‍
      <strong>Bridgefy SDK</strong></span>
      <strong><br/>
      · </strong>Is for software developers to use<br/>
      · Can’t be installed on your phone<br/>
      · Is software that powers other apps so they can work without Internet<br/><br/>‍
      <span class="text-span-2">
      <strong>Bridgefy App</strong>
      </span><strong><br/>
      · </strong>Is a mobile app for anybody to use<br/>
      · Can be installed on iOS and Android devices (<a href="https://bridgefy.me/" target="_blank" className="link-15">click here to learn more!</a>)<br/>
      · Is free and can be used right away, so tell all of your friends!
    `
  },
  {
    id: 3,
    question: 'How many devices need to have access to the Intenet for a Mesh Network to work?',
    answer: `
      None! Mesh networks can be 100% offline. Literally, all devices can be on airplane mode with
      Bluetooth on, and they can keep sharing content uninterruptedly. Optionally, developers can choose
      to implement offline networks or hybrid networks, which do use some devices’ Internet.
      <a href="https://bridgefy.me/sdk/" target="_blank">
      <span class="text-span-4">
      Click here to learn more about Bridgefy SDK specifics</span></a>.
    `
  },
  {
    id: 4,
    question: 'What kind of file sizes can you share using Bridgefy SDK?',
    answer: `
      Bridgefy currently works using Bluetooth Low-Energy and Wi-Fi Direct, which means there is a large
      range of options regarding packet types. Text, small files, small images, prompts, triggers,
      locations, and some types of payments are supported. You can also transfer larger files, but
      the reliability of the network is decreased because they take longer to send. Read more specific
      information about package sizes on the developer console FAQ after you log in.
    `
  },
  {
    id: 5,
    question: 'What languages and wrappers are compatible with Bridgefy SDK?',
    answer: `
      We proudly have versions of the Bridgefy SDK for the following:<br/>‍<br/>
      <strong class="bold-text-3">
        · Android (Kotlin)<br/>
        · iOS (Swift)<br/></strong>
        · React Native (coming soon)<br/>
        · Cordova (coming soon)
    `
  },
  {
    id: 6,
    question: `I'm a development company and want to use the Bridgefy SDK for my customers, what's the next step?`,
    answer: `
      We love working with dev shops, and are currently building a list of Bridgefy-approved companies
      that we can refer customers to. We oftentimes get asked if we build custom apps, and thus need
      reliable partners to work on those projects together.
      <a href="mailto:contact@bridgefy.me?subject=Dev%20Shop" class="link-16">Reach out to us</a>
      and we’ll be happy to tell you about how we can partner up!
    `
  }
];