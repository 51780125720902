import SdkFooter from "../../shared/modules/SdkFooter/SdkFooter";
import SdkNavbar from "../../shared/modules/SdkNavbar/SdkNavbar";
import TermsOfServiceContent from "./TermsOfServiceContent";

export default function TermsofService() {
  return(
    <div className="body-2">
      <SdkNavbar></SdkNavbar>
      <TermsOfServiceContent></TermsOfServiceContent>
      <SdkFooter></SdkFooter>
    </div>
  );
}