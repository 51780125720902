import useCaseMain2 from '../../shared/images/usecaseMain2.png';
import useCasesChat from '../../shared/images/useCasesChat.png';
import useCasesGame from '../../shared/images/useCasesGame.png';
import useCasesPay from '../../shared/images/useCasesPay.png';
import useCasesSocial_1 from '../../shared/images/useCasesSocial_1.png';
import frame5 from '../../shared/images/Frame-5.png';
import frame2 from '../../shared/images/Frame-2.png';
import frame3 from '../../shared/images/Frame-3.png';
import imgDashboard from '../../shared/images/img_dashboard.png';

export default function UseCasesContent() {
  return(
    <div className="div-block-3">
      <section className="section-layout219 caseshero usecasess">
        <div className="page-padding center">
          <div className="container-large">
            <div className="padding-vertical padding-xhuge">
              <img
                src={useCaseMain2}
                loading="lazy"
                data-w-id="93a27497-234c-6545-2490-dc3bff2e3551"
                sizes="(max-width: 479px) 90vw, (max-width: 767px) 72vw, (max-width: 991px) 63vw, (max-width: 1919px) 36vw, 640px"
                alt=""
                className="image-16"/>
              <h2 className="heading-2 centered bigger">Boost App Engagement</h2>
              <p className="paragraph-2 bigger">Let people use your app when they don’t have access to the Bridgefy SDK and watch engagement and revenue grow!</p>
            </div>
          </div>
        </div>
      </section>
      <section className="section-layout210">
        <div className="page-padding">
          <div className="container-large">
            <div className="padding-vertical padding-xhuge">
              <div className="w-layout-grid layout210_component usecasegrid">
                <div id="w-node-_8124d21d-cdd5-53f0-cd81-7f754acb3499-3d29d912" className="layout210_image-wrapper">
                  <img
                    src={useCasesChat}
                    loading="lazy"
                    data-w-id="8124d21d-cdd5-53f0-cd81-7f754acb349a"
                    alt=""
                    className="image-18"/>
                </div>
                <div id="w-node-_8124d21d-cdd5-53f0-cd81-7f754acb349b-3d29d912" className="layout210_content">
                  <div className="margin-bottom margin-xsmall sdkweb">
                    <div className="text-weight-semibold homee white">CONNECTED, OFF THE GRID</div>
                  </div>
                  <div className="margin-bottom margin-small">
                    <h2 className="heading-4 homee"><strong>Text Messages</strong></h2>
                  </div>
                  <div className="margin-bottom margin-medium">
                    <p className="text-size-medium homee light usecase">Sharing text messages with people nearby shouldn’t cost your customers their data, or you in server expenses. Whether it’s while playing a game or on their favorite social media app, you can let users share texts with each other using Bridgefy mesh networks instead! Some use cases for text messaging:</p>
                  </div>
                  <div className="margin-bottom margin-small">
                    <ul className="layout210_list home light">
                      <li className="layout210_item"><p className="usecase">Messaging apps</p>
                      </li>
                      <li className="layout210_item"><p className="usecase">Gaming apps</p>
                      </li>
                      <li className="layout210_item"><p className="usecase">Networking apps</p>
                      </li>
                      <li className="layout210_item"><p className="usecase">Sports stadium apps</p>
                      </li>
                      <li className="layout210_item"><p className="usecase">Concert and music festival apps</p>
                      </li>
                    </ul>
                  </div>
                  <div className="button-row">
                    <div className="button-wrapper">
                      <a
                        href="https://developer.bridgefy.me"
                        target="_blank"
                        rel="noreferrer"
                        className="button-tertiary margin-left margin-xxsmall w-inline-block">
                        <div className="text-block">Sign Up</div>
                        <div className="button-tertiary_icon w-embed"><svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M6 3L11 8L6 13" stroke="CurrentColor" strokeWidth="1.5"></path>
                          </svg></div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section-layout210">
        <div className="page-padding">
          <div className="container-large">
            <div className="padding-vertical padding-xhuge">
              <div className="w-layout-grid layout210_component usecasegrid">
                <div id="w-node-ac657567-aaa5-31ef-2180-f9c42d796599-3d29d912" className="layout210_image-wrapper">
                  <img
                    src={useCasesGame}
                    loading="lazy"
                    data-w-id="ac657567-aaa5-31ef-2180-f9c42d79659a"
                    bind="ac657567-aaa5-31ef-2180-f9c42d79659a"
                    alt=""
                    className="image-18"/>
                </div>
                <div id="w-node-ac657567-aaa5-31ef-2180-f9c42d79659b-3d29d912" className="layout210_content">
                  <div className="margin-bottom margin-xsmall sdkweb">
                    <div className="text-weight-semibold homee white">INTERNET? NO THANKS</div>
                  </div>
                  <div className="margin-bottom margin-small">
                    <h2 className="heading-4 homee"><strong bind="ac657567-aaa5-31ef-2180-f9c42d7965a1">Game Apps</strong></h2>
                  </div>
                  <div className="margin-bottom margin-medium">
                    <p className="text-size-medium homee light usecase">Imagine kids at school being able to play your game without needing a Wi-Fi network or huge amounts of data. Turn-based games oftentimes only need to share short and light text strings between players, and your interface does the rest. Open up the fun to people who can’t or don’t want to use their data, and watch your numbers skyrocket! Some examples of turn-based games that Bridgefy can make work offline:</p>
                  </div>
                  <div className="margin-bottom margin-small">
                    <ul className="layout210_list home light">
                      <li className="layout210_item"><p className="usecase">Poker</p>
                      </li>
                      <li className="layout210_item"><p className="usecase">Blackjack</p>
                      </li>
                      <li className="layout210_item"><p className="usecase">Chess</p>
                      </li>
                      <li className="layout210_item"><p className="usecase">Risk</p>
                      </li>
                      <li className="layout210_item"><p className="usecase">Uno</p>
                      </li>
                      <li className="layout210_item"><p className="usecase">Pokémon</p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section-layout210">
        <div className="page-padding">
          <div className="container-large">
            <div className="padding-vertical padding-xhuge">
              <div className="w-layout-grid layout210_component usecasegrid">
                <div id="w-node-deb54b44-abde-0dc8-949e-4db2025e0992-3d29d912" className="layout210_image-wrapper">
                  <img
                    src={useCasesPay}
                    loading="lazy"
                    data-w-id="deb54b44-abde-0dc8-949e-4db2025e0993"
                    alt=""
                    className="image-18"/>
                </div>
                <div id="w-node-deb54b44-abde-0dc8-949e-4db2025e0994-3d29d912" className="layout210_content">
                  <div className="margin-bottom margin-xsmall sdkweb">
                    <div className="text-weight-semibold homee white">ACCESSIBLE PAYMENT METHODS</div>
                  </div>
                  <div className="margin-bottom margin-small">
                    <h2 className="heading-4 homee"><strong>Payments</strong></h2>
                  </div>
                  <div className="margin-bottom margin-medium">
                    <p className="text-size-medium homee light usecase">Have you ever been on a trip and found yourself without access to your data? Ever been to a store where they didn’t have a credit card terminal? Have you ever been at a stadium or concert, and couldn’t pay for something because of poor connectivity? Bridgefy allows payments transactions to happen even when you don’t have access to a data connection! Some examples of situations in which an offline payments app would be helpful:</p>
                  </div>
                  <div className="margin-bottom margin-small">
                    <ul className="layout210_list home light">
                      <li className="layout210_item"><p className="usecase">Music events</p>
                      </li>
                      <li className="layout210_item"><p className="usecase">Sports events</p>
                      </li>
                      <li className="layout210_item"><p className="usecase">Rural locations</p>
                      </li>
                      <li className="layout210_item"><p className="usecase">While traveling internationally</p>
                      </li>
                      <li className="layout210_item"><p className="usecase">When you want to transfer crypto</p>
                      </li>
                    </ul>
                  </div>
                  <p className="text-size-medium homee light usecase small">* Payments apps running on the Bridgefy SDK require at least one person to have Internet access to validate transactions. One way to avoid this necessity is to use credit on your app that can be discounted; in this case, no one would need Internet access for the transaction to happen</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section-layout210">
        <div className="page-padding">
          <div className="container-large">
            <div className="padding-vertical padding-xhuge">
              <div className="w-layout-grid layout210_component usecasegrid">
                <div id="w-node-fa9063f0-d7f7-b773-84fc-168adad66de7-3d29d912" className="layout210_image-wrapper">
                  <img
                    src={useCasesSocial_1}
                    loading="lazy"
                    data-w-id="fa9063f0-d7f7-b773-84fc-168adad66de8"
                    alt=""
                    className="image-18"/>
                </div>
                <div id="w-node-fa9063f0-d7f7-b773-84fc-168adad66de9-3d29d912" className="layout210_content">
                  <div className="margin-bottom margin-xsmall sdkweb">
                    <div className="text-weight-semibold homee white">SHARE IT, NO INTERNET REQUIRED</div>
                  </div>
                  <div className="margin-bottom margin-small">
                    <h2 className="heading-4 homee"><strong>Social Media</strong></h2>
                  </div>
                  <div className="margin-bottom margin-medium">
                    <p className="text-size-medium homee light usecase">We oftentimes believe that the major social media companies have a monopoly on their category, but what if you built a dating app that worked in crowded bars or at concerts? What if your networking app was the only one that worked at large conferences and conventions? You’d own the market, that’s what! Some examples of apps that could (and should) work without the Internet:</p>
                  </div>
                  <div className="margin-bottom margin-small">
                    <ul className="layout210_list home light">
                      <li className="layout210_item"><p className="usecase">Business networking apps</p>
                      </li>
                      <li className="layout210_item"><p className="usecase">School apps</p>
                      </li>
                      <li className="layout210_item"><p className="usecase">Dating apps</p>
                      </li>
                      <li className="layout210_item"><p className="usecase">Neighborhood apps</p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="div-block-4"></div>
      <section className="section-layout210">
        <div className="page-padding">
          <div className="container-large">
            <div className="padding-vertical padding-xhuge">
              <div className="w-layout-grid layout210_component">
                <div className="layout210_image-wrapper">
                  <img
                    src={frame5}
                    loading="lazy"
                    data-w-id="907657ef-14a3-a0e5-34c9-4e27862a5f25"
                    sizes="(max-width: 767px) 90vw, (max-width: 991px) 39vw, (max-width: 1919px) 36vw, 533.328125px"
                    alt=""/>
                </div>
                <div id="w-node-_907657ef-14a3-a0e5-34c9-4e27862a5f26-3d29d912" className="layout210_content">
                  <div className="margin-bottom margin-xsmall"></div>
                  <div className="margin-bottom margin-small">
                    <h2 className="heading-4 homee"><strong bind="907657ef-14a3-a0e5-34c9-4e27862a5f2c">Create unique and different apps</strong></h2>
                  </div>
                  <div className="margin-bottom margin-medium">
                    <p className="text-size-medium homee light">The app store is saturated with dating apps, messaging apps, and games. Oftentimes they offer the same identical features. By integrating the Bridgefy SDK, you’ll stand out in your users’ eyes (and phones) by being the only app of its kind that works without the Internet. Imagine how popular it would become!</p>
                  </div>
                  <div className="button-row">
                    <div className="button-wrapper">
                      <a
                        href="https://developer.bridgefy.me"
                        target="_blank"
                        rel="noreferrer"
                        className="button-tertiary margin-left margin-xxsmall w-inline-block">
                        <div className="text-block">Sign Up</div>
                        <div className="button-tertiary_icon w-embed"><svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M6 3L11 8L6 13" stroke="CurrentColor" strokeWidth="1.5"></path>
                          </svg></div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section-layout192">
        <div className="page-padding">
          <div className="container-large">
            <div className="padding-vertical padding-xhuge">
              <div className="w-layout-grid layout192_component">
                <div className="testimonial13_client-image-wrapper">
                  <img
                    src={frame2}
                    loading="lazy"
                    data-w-id="310625d7-f222-76c5-bfbd-5d7038bca508"
                    sizes="(max-width: 767px) 90vw, (max-width: 991px) 40vw, (max-width: 1919px) 41vw, 600px"
                    alt=""/>
                </div>
                <div id="w-node-_310625d7-f222-76c5-bfbd-5d7038bca509-3d29d912" className="layout192_content">
                  <div className="margin-bottom margin-xsmall"></div>
                  <div className="margin-bottom margin-small">
                    <h2 className="heading-5">Reach markets that are ignored by everyone else</h2>
                  </div>
                  <div className="margin-bottom margin-small">
                    <p className="text-size-medium homee light">It’s the year 2022 and 40% of smartphone owners still don’t have a mobile Internet subscription. However, all of these people want to participate in their local societies and economies, as well as stay safe and receive educational content. By opening up your app to this huge population, you’d be able to service markets that everyone else is ignoring. Yours for the taking!</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section-layout210">
        <div className="page-padding">
          <div className="container-large">
            <div className="padding-vertical padding-xhuge">
              <div className="w-layout-grid layout210_component">
                <div id="w-node-_39c853c2-90c9-1308-5438-ba199b86157c-3d29d912" className="layout210_content">
                  <div className="margin-bottom margin-xsmall"></div>
                  <div className="margin-bottom margin-small">
                    <h2 className="heading-4 homee">
                      <strong>User analytics no one else has</strong></h2>
                  </div>
                  <div className="margin-bottom margin-medium">
                    <p className="text-size-medium homee light">Naturally, if your app is the only one that works during crowded concerts or in cities with poor telco infrastructure, you’ll also be the only one that can know how these users behave. Our powerful dashboard shows you how many people are using your app offline, what kind of device they’re using, their activity, and much more, while always respecting their privacy and anonymity. More data leads to better product decisions, which leads to higher engagement!</p>
                  </div>
                  <div className="button-row">
                    <div className="button-wrapper">
                      <a
                        href="https://developer.bridgefy.me"
                        target="_blank"
                        rel="noreferrer"
                        className="button-tertiary margin-left margin-xxsmall w-inline-block">
                        <div className="text-block">Sign Up</div>
                        <div className="button-tertiary_icon w-embed"><svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M6 3L11 8L6 13" stroke="CurrentColor" strokeWidth="1.5"></path>
                          </svg></div>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="layout210_image-wrapper">
                  <img
                    src={imgDashboard}
                    loading="lazy"
                    data-w-id="39c853c2-90c9-1308-5438-ba199b861599"
                    sizes="(max-width: 767px) 90vw, (max-width: 991px) 49vw, (max-width: 1919px) 46vw, 666.671875px"
                    alt=""/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section-layout192">
        <div className="page-padding">
          <div className="container-large">
            <div className="padding-vertical padding-xhuge">
              <div className="w-layout-grid layout192_component">
                <div className="testimonial13_client-image-wrapper">
                  <img
                    src={frame3}
                    loading="lazy"
                    data-w-id="371eab64-a40a-575f-03b1-8f4f903a93ad"
                    sizes="(max-width: 767px) 90vw, (max-width: 991px) 40vw, (max-width: 1919px) 41vw, 600px"
                    alt=""/>
                </div>
                <div id="w-node-_371eab64-a40a-575f-03b1-8f4f903a93ae-3d29d912" className="layout192_content">
                  <div className="margin-bottom margin-small">
                    <h2 className="heading-5"><strong >Get ready to take off!</strong></h2>
                  </div>
                  <div className="margin-bottom margin-small">
                    <p className="text-size-medium homee light">Watch engagement and revenue skyrocket once you’ve empowered your users who don’t have access to the Internet. We constantly improve the Bridgefy technology and add useful new features to the SDK and dashboard, so you can offer better apps constantly!</p>
                  </div>
                  <div className="button-row">
                    <div className="button-wrapper">
                      <a
                        href="https://developer.bridgefy.me"
                        target="_blank"
                        rel="noreferrer"
                        className="button-tertiary margin-left margin-xxsmall w-inline-block">
                        <div className="text-block">Sign Up</div>
                        <div className="button-tertiary_icon w-embed"><svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M6 3L11 8L6 13" stroke="CurrentColor" strokeWidth="1.5"></path>
                          </svg></div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
