import { Parser } from "html-to-react";
import { useState } from "react";

export default function AppFaqItem ({faq}) {
  const [isActive, setIsActive] = useState(false);

  function changeState() {
    setIsActive(!isActive);
  }

  return (
    <div className="faq2_accordion">
      <div
        data-w-id="ea08ce9b-21fa-2d82-80dc-4bf915323a7d"
        className="faq2_question"
        onClick={() => changeState()}>
        <div className="text-size-medium-2 text-weight-bold">{faq.question}</div>
        <img
          src="https://uploads-ssl.webflow.com/611612d8f3fe0984cee8d325/6168d2e454973af7dd55449c_icon_chevron.svg"
          loading="lazy"
          bind="ea08ce9b-21fa-2d82-80dc-4bf915323a80"
          alt=""
          className="faq2_icon"/>
      </div>
      <div
        style={{height: '0px'}}
        className={isActive ? 'faq_answer_open faq2_answer' : 'faq2_answer'}>
        <div
          className="faq_answernew">
          { Parser().parse(faq.answer) }
        </div>
      </div>
    </div>
  );
}