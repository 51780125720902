export default function DevshopsNavbar() {
  return(
    <div
      data-animation="default"
      className="navbar1_component home donate w-nav"
      data-easing2="ease"
      data-easing="ease"
      data-collapse="medium"
      data-w-id="68f0f4eb-ec21-efad-0377-10de0a4e8169"
      role="banner"
      data-duration="400" bind="68f0f4eb-ec21-efad-0377-10de0a4e8169">
      <div
        className="navbar1_container home">
        <a
          href="#start"
          className="navbar1_logo-link w-nav-brand">
          <img
            src="https://uploads-ssl.webflow.com/62d5fdd7d8412d582512e77c/62d5fdd7d8412d567f12e7ad_BridgefyLogo1.svg"
            loading="lazy"
            width="150"
            alt=""/>
          </a>
          <nav role="navigation" className="navbar1_menu-2 home w-nav-menu">
            <a href="#program" className="navbar1_link-2 home donate w-nav-link">PROGRAM</a>
            <a href="#revenue" className="navbar1_link-2 home donate w-nav-link">FEATURES</a>
            <a href="#cases" className="navbar1_link-2 home donate w-nav-link">Compatibility</a>
            <div className="navbar1_menu-buttons">
              <div className="navbar1_button-wrapper">
                <a href="#form" className="button-3 max-width-full red home w-button">Sign Up</a>
              </div>
            </div>
          </nav>
        <div className="navbar1_menu-button w-nav-button">
          <div className="menu-icon1">
            <div className="menu-icon1_line-top"></div>
            <div className="menu-icon1_line-middle">
              <div className="menu-icon_line-middle-inner"></div>
            </div>
            <div className="menu-icon1_line-bottom"></div>
          </div>
        </div>
      </div>
    </div>
  );
}