import SdkFooter from "../../shared/modules/SdkFooter/SdkFooter";
import SdkNavbarDark from "../../shared/modules/SdkNavbarDark/SdkNavbarDark";
import SdkContent from "./SdkContent";
import HeroSdk from "./HeroSdk";

export default function SdkHome() {
  return (
    <div className="div homediv">
      <SdkNavbarDark children={<HeroSdk/>}></SdkNavbarDark>
      <SdkContent></SdkContent>
      <SdkFooter></SdkFooter>
    </div>
  );
}