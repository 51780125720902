import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import AppHome from '../components/AppHome/AppHome';
import ContactUs from "../components/ContactUs/ContactUs";
import Devshops from "../components/Devshops/Devshops";
import Faqs from "../components/Faqs/Faqs";
import { NotFound } from "../components/NotFound/NotFound";
import PrivacyPolicy from "../components/PrivacyPolicy/PrivacyPolicy";
import SdkHome from "../components/SdkHome/SdkHome";
import TermsofService from "../components/TermsOfService/TermsOfService";
import UseCases from "../components/UseCases/UseCases";

export default function BridgefyRoutes() {
  return (
    <BrowserRouter>
      <Routes>
          <Route path="/app" exact element={<AppHome/>}>
          </Route>
          <Route path="/" exact element={<SdkHome/>}>
          </Route>
          <Route path="/use-cases" exact element={<UseCases/>}>
          </Route>
          <Route path="/faq" exact element={<Faqs/>}>
          </Route>
          <Route path="/privacy-policy" exact element={<PrivacyPolicy/>}>
          </Route>
          <Route path="/terms-of-service" exact element={<TermsofService/>}>
          </Route>
          <Route path="/devshops" exact element={<Devshops></Devshops>}>
          </Route>
          <Route path="/contact-us" exact element={<ContactUs></ContactUs>}>
          </Route>
          <Route path="*" exact element={<NotFound></NotFound>}>
          </Route>
      </Routes>
    </BrowserRouter>
  );
}
