
import AppFaq from '../../shared/modules/AppFaq/AppFaq';
import Footer from '../../shared/modules/AppFooter/AppFooter';
import AppNavbar from '../../shared/modules/HomeNavbar/AppNavbar';
import Testimonial from '../../shared/modules/Testimonial/Testimonial';
import ThePress from '../../shared/modules/ThePress/ThePress';
import HomeContent from './HomeContent';

export default function Home() {
  return (
    <div className='body-3'>
      <AppNavbar></AppNavbar>
      <HomeContent></HomeContent>
      <Testimonial></Testimonial>
      <ThePress></ThePress>
      <AppFaq></AppFaq>
      <Footer></Footer>
    </div>
  );
}


